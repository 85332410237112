<template>
  <div class="col-12 shadow py-4 px-4">
    <div
      class="row gx-5 py-4 d-flex align-items-center"
      :class="$style.minHeight60"
    >
      <div class="col-10 col-md-8 col-lg-6 mx-auto">
        <h5 class="fw-bold mb-3 text-center">
          歡迎加入娜娜家會員
        </h5>
        <div
          v-for="field in fieldList"
          :key="field"
        >
          <InputPassword
            v-if="field.toLowerCase().includes('password')"
            :id="field"
            v-model:input="state[field]"
            class="mb-4"
            :type="field"
            :required="!!(rules[field]?.requiredHelper)"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
          />
          <InputDatePicker
            v-else-if="field === 'birthday'"
            v-model:input="state[field]"
            class="form-control bg-transparent mb-4 p-0"
            :class="{ 'border-danger': v$[field]?.$error }"
            :input-format="'yyyy-MM-dd'"
            :clearable="false"
            placeholder="請輸入生日"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            :style="{
              '--vdp-selected-bg-color': '#cbbbae',
              '--vdp-hover-bg-color': '#d4c4b7'
            }"
            @focus="v$[field].$touch"
          />
          <!-- countySelect -->
          <div
            v-else-if="field === 'county'"
            class="mb-4"
          >
            <div class="col-12 position-relative">
              <div class="d-flex flex-column">
                <select
                  :id="field"
                  v-model="state[field]"
                  class="form-select"
                  :class="[{'border-danger': v$.county?.$error}]"
                  :placeholder="labelMap[field].placeholder"
                  @focus="v$[field].$touch"
                >
                  <option
                    :value="''"
                    disabled="disabled"
                  >
                    {{ labelMap[field].placeholder }}
                  </option>
                  <option
                    v-for="city in cities"
                    :key="city.CityName"
                    :value="city.CityName"
                  >
                    {{ city.CityName }}
                  </option>
                </select>
              </div>
              <small
                class="text-danger position-absolute start-0 error-text"
                :class="[{'show': v$[field]?.$error}]"
              >{{ v$[field]?.$errors[0]?.$message || '' }}</small>
            </div>
          </div>
          <Input
            v-else
            :id="field"
            v-model:input.trim="state[field]"
            class="mb-4"
            :type="field"
            :required="!!(rules[field]?.requiredHelper)"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
          />
        </div>
        <button
          class="btn btn-primary rounded-pill text-white w-100 mb-3"
          type="button"
          @click="createMemberID"
        >
          立即加入
        </button>
        <div class="text-primary text-center">
          點擊註冊即表示同意 <span class="text-info">會員權益聲明</span> 與 <span class="text-info">商店服務條款</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from '@/components/Input.vue'
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, passwordHelper, mobileHelper, emailHelper, helpers, sameAs } from '@/hooks/useVuelidate.js'
import { ref, inject, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useErrorMessage } from '@/hooks/errorMessage.js'
import { useRouter } from 'vue-router'
import cities from '@/hooks/cities.json'
import InputDatePicker from '@/components/InputDatePicker'

export default {
  name: 'LoginSignup',
  components: {
    Input, InputPassword, InputDatePicker
  },
  setup (props) {
    const { dispatch, getters } = useStore()
    const { push } = useRouter()
    const { getErrorMessage } = useErrorMessage()

    const fbIDToken = computed(() => getters['validate/getFbIDToken'])

    const email = ref('')
    const cell = ref('')
    const showName = ref('')
    const password = ref('')
    const checkPassword = ref('')
    const lastName = ref('')
    const firstName = ref('')
    const birthday = ref(null)
    const county = ref('')
    const address = ref('')

    const setAlert = inject('setAlert')

    const fieldList = [
      'cell',
      'password',
      'checkPassword',
      'email',
      'showName',
      'lastName',
      'firstName',
      'birthday',
      'county',
      'address'
    ]

    const labelMap = {
      cell: {
        label: '手機',
        placeholder: '請輸入手機'
      },
      password: {
        label: '密碼',
        placeholder: '請輸入密碼'
      },
      checkPassword: {
        label: '再次輸入密碼',
        placeholder: '請再次輸入密碼'
      },
      email: {
        label: '電子信箱',
        placeholder: '請輸入電子信箱'
      },
      showName: {
        label: 'Facebook公開名稱',
        placeholder: '請輸入Facebook公開名稱'
      },
      lastName: {
        label: '姓氏',
        placeholder: '請輸入姓氏'
      },
      firstName: {
        label: '名字',
        placeholder: '請輸入名字'
      },
      birthday: {
        label: '生日',
        placeholder: '請輸入生日'
      },
      county: {
        label: '縣市',
        placeholder: '請輸入縣市'
      },
      address: {
        label: '地址',
        placeholder: '請輸入地址'
      }
    }

    const state = reactive({
      cell,
      password,
      checkPassword,
      email,
      showName,
      lastName,
      firstName,
      birthday,
      county,
      address
    })

    const rules = {
      cell: { requiredHelper, mobileHelper },
      password: { requiredHelper, passwordHelper },
      checkPassword: { requiredHelper, passwordHelper, sameAsRef: helpers.withMessage('請確認兩次密碼是否一致', sameAs(password)) },
      email: { emailHelper },
      showName: { requiredHelper },
      lastName: { requiredHelper },
      firstName: { requiredHelper },
      birthday: { requiredHelper },
      county: { requiredHelper },
      address: { requiredHelper }
    }

    const v$ = useVuelidate(rules, state)

    const createMemberID = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        let payload = {
          email: email.value,
          cell: cell.value,
          showName: showName.value,
          pwd: password.value,
          lastName: lastName.value,
          firstName: firstName.value,
          birthday: birthday.value,
          county: county.value,
          address: address.value,
          activateByEmail: false
        }

        try {
          let response = null
          if (fbIDToken.value) {
            payload = {
              ...payload,
              id_token: fbIDToken.value,
              sso_type: 'facebook'
            }
            response = await dispatch('member/createMemberIDSSO', { payload })
          } else {
            response = await dispatch('member/createMemberID', { payload })
          }

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            if (fbIDToken.value) {
              setAlert(true, true, '註冊成功。')
              push('/')
            } else {
              setAlert(true, true, '註冊成功。請再次登入。')
              push('/login')
            }
          }

          return response
        } catch (error) {
          console.log(error)
          // console.log('get user info error: ' + api + ', ' + error)
          setAlert(true, false, `註冊失敗: ${error}`)
        // window.setTimeout(() => location.reload(), 2000)
        }
      }
    }

    return {
      createMemberID,
      v$,
      labelMap,
      rules,
      fieldList,
      state,
      cities,
      email,
      cell,
      showName,
      password,
      checkPassword,
      lastName,
      firstName,
      birthday,
      county,
      address
    }
  }
}
</script>
<style lang="scss" module>

.minHeight60 {
  min-height: 60vh;
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}

</style>
