<template>
  <div class="container-md">
    <div class="row align-items-center justify-content-center pb-4">
      <div class="col-12">
        <Breadcrumb :bread-crumbs="breadCrumbs" />
        <keep-alive>
          <component :is="currentTab" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import ComponentLogin from '@/components/LoginNokaptcha.vue'
import ComponentSignup from '@/components/Signup.vue'
import ComponentForgotPassword from '@/components/ForgotPassword.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { ref, provide, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
export default {
  name: 'Login',
  components: {
    ComponentForgotPassword,
    ComponentLogin,
    ComponentSignup,
    Breadcrumb
  },
  setup () {
    const route = useRoute()

    const breadCrumbs = computed(() => ([{ title: route.name === 'Login' ? '登入' : route.name === 'Signup' ? '註冊' : '忘記密碼' }]))

    const account = ref('')
    const currentTab = ref(route.name === 'Login' ? 'ComponentLogin' : route.name === 'Signup' ? 'ComponentSignup' : 'ComponentForgotPassword')
    const setCurrentTab = (tab) => {
      currentTab.value = tab
    }

    watch(() => route.name, () => {
      currentTab.value = route.name === 'Login' ? 'ComponentLogin' : route.name === 'Signup' ? 'ComponentSignup' : 'ComponentForgotPassword'
    })

    provide('account', account)

    return {
      currentTab, setCurrentTab, breadCrumbs
    }
  }
}
</script>
<style lang="scss" module>

</style>
