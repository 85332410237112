<template>
  <div class="col-12 col-xxl-8 mx-auto shadow py-4 px-4">
    <div class="row gx-5 py-4">
      <div class="col-8 mx-auto d-flex flex-column align-items-center justify-content-center">
        <div class="w-100">
          <h5 class="fw-bold mb-3">
            歡迎登入娜娜家會員
          </h5>
          <p class="mb-5">
            請在下面輸入您的手機號碼，將重置您的密碼為生日。
          </p>
        </div>
        <Input
          id="resetPwdAccount"
          v-model:input="state.resetPwdAccount"
          type="text"
          class="w-100 mb-5"
          :required="true"
          :autocomplete="'off'"
          :label="labelMap.resetPwdAccount.label"
          :placeholder="labelMap.resetPwdAccount.placeholder"
          :err-message="v$.resetPwdAccount?.$errors[0]?.$message || ''"
          @focus="v$.resetPwdAccount.$touch"
          @enter="createResetPasswordRequest"
        />
        <div>
          <button
            ref="loginBtn"
            class="btn btn-primary rounded-pill text-white mb-3"
            type="button"
            @click="createResetPasswordRequest"
          >
            重置我的密碼
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from '@/components/Input.vue'
import useVuelidate from '@vuelidate/core'
import { requiredHelper, emailHelper, mobileHelper } from '@/hooks/useVuelidate.js'

import { ref, inject, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useErrorMessage } from '@/hooks/errorMessage.js'
import { useRouter } from 'vue-router'

export default {
  name: 'ComponentLogin',
  components: {
    Input
  },
  async setup (props) {
    const { dispatch } = useStore()
    const { getErrorMessage } = useErrorMessage()
    const { push } = useRouter()

    const account = inject('account')
    const setAlert = inject('setAlert')

    const showResetPasswordModal = ref(false)
    const showRevalidationModal = ref(false)

    const setShowResetPasswordModal = (show) => {
      showResetPasswordModal.value = show
      v$.value.$reset()
    }
    const setShowRevalidationModal = (show) => {
      showRevalidationModal.value = show
      v$.value.$reset()
    }

    const fieldListLogin = ['account', 'password', 'verification']

    const labelMap = {
      resetPwdAccount: {
        label: '手機號碼',
        placeholder: '請輸入手機號碼'
      }
    }

    const state = reactive({
      accountType: 1,
      resetPwdAccount: ''
    })

    const rules = computed(() => {
      const ruleMapResetPwd = {
        accountType: { requiredHelper },
        resetPwdAccount: { requiredHelper }
      }

      if (state.accountType === '0') {
        return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper, emailHelper } })
      } else if (state.accountType === '1') {
        return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper, mobileHelper } })
      } else {
        return Object.assign({}, ruleMapResetPwd, { resetPwdAccount: { requiredHelper } })
      }
    })

    const v$ = useVuelidate(rules, state)

    const createResetPasswordRequest = async () => {
      v$.value.$touch()
      const payload = {
        account: state.resetPwdAccount
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch('validate/updateResetPasswordWithBirthdayRequest', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            push('/login')
          } else {
            setAlert(true, true, '發送成功，密碼已重置為註冊時填入的生日。')
            push('/login')
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `發送失敗: ${error}`)
          push('/login')
        }
      }
    }

    return {
      v$,
      fieldListLogin,
      account,
      state,
      labelMap,
      showResetPasswordModal,
      showRevalidationModal,
      setShowResetPasswordModal,
      setShowRevalidationModal,
      createResetPasswordRequest
    }
  }
}
</script>
<style lang="scss" module>

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

</style>
