<template>
  <div class="col-12 shadow py-4 px-4">
    <div
      class="row gx-5 py-4 align-items-center"
      :class="$style.minHeight60"
    >
      <div class="col-10 col-md-8 col-lg-6 mx-auto">
        <h5 class="fw-bold mb-3 text-center">
          歡迎登入娜娜家會員
        </h5>
        <div
          v-for="field in fieldListLogin"
          :key="field"
          :class="styleMapLogin?.[field] || ''"
        >
          <InputPassword
            v-if="field.toLowerCase().includes('password')"
            :id="field"
            v-model:input="state[field]"
            :type="field"
            :required="true"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
            @enter="readMemberID"
          />
          <Input
            v-else
            :id="field"
            v-model:input.trim="state[field]"
            :required="true"
            :autocomplete="'on'"
            :label="labelMap[field].label"
            :placeholder="labelMap[field].placeholder"
            :err-message="v$[field]?.$errors[0]?.$message || ''"
            @focus="v$[field].$touch"
            @enter="readMemberID"
          />
        </div>
        <!-- 驗證碼 -->
        <!-- <div
            class="col-6 d-flex flex-column align-items-center justify-content-between"
          >
            <div :class="$style[`height-100px`]">
              <img
                v-if="kaptcha"
                :src="kaptcha"
                role="button"
                class="img-contain"
                :class="$style[`img-contain`]"
                title="看不清楚換一張"
                @click="readKaptcha"
              >
            </div>
            <small class="text-xs">(點選圖片重新取得一組驗證碼)</small>
          </div> -->
        <div class="w-100 mb-1">
          <router-link
            class="text-decoration-none link-dark"
            to="/forgot-password"
          >
            忘記密碼？
          </router-link>
        </div>

        <div class="col-12">
          <button
            ref="loginBtn"
            class="btn btn-primary rounded-pill text-white w-100 mb-3"
            type="button"
            @click="readMemberID"
          >
            開始購物
          </button>
          <div class="w-100 text-center mb-1 small">
            還不是會員嗎？
            <router-link
              to="/signup"
              class="text-decoration-none"
            >
              立即註冊
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div
        class="col-12 col-md-6 order-1 order-md-2 d-flex flex-column justify-content-center position-relative mb-5 mb-md-0"
        :class="[$style.orBorder]"
      >
        <div
          class="position-absolute bg-white d-block p-2 or"
          :class="[$style.or]"
        >
          OR
        </div>
        <div
          class="button text-center"
          type="button"
          style="z-index: 3"
          scope="public_profile,email"
          @click="facebookOnLoginIcon"
        >
          <img
            src="@/assets/img/fblogin_button.png"
            style="max-width: 100%"
          >
        </div>
      </div> -->
    </div>

    <!-- <div class="w-100 text-center">
      <span
        class="pointer small"
        role="button"
        @click="setShowRevalidationModal(true)"
      >沒收到驗證通知？</span>
    </div> -->

    <teleport to="#modalWrap">
      <!-- 重新發送驗證通知 -->
      <div v-show="showRevalidationModal">
        <div
          :class="$style[`shadow`]"
          @click="setShowRevalidationModal(false)"
        />
        <div
          id="forgotPwdModal"
          class="modal-lg position-fixed top-50 start-50 translate-middle"
          :class="$style[`modal`]"
        >
          <div
            class="modal-dialog modal-dialog-centered"
            role="document"
          >
            <div class="modal-content pop-up bg-white rounded-0">
              <div class="modal-body text-center p-4">
                <h6 class="text-primary mb-3">
                  請選擇帳號型態並輸入帳號，<br
                    class="d-inline d-md-none"
                  >我們將重新發送驗證通知。
                </h6>

                <div class="position-relative mb-4">
                  <div class="d-flex flex-column">
                    <select
                      v-model="state.accountType"
                      class="form-select"
                      :class="{ 'border-danger': v$.accountType?.$error }"
                      name="typeID"
                      required
                      @focus="v$.accountType.$touch"
                    >
                      <option
                        value="null"
                        disabled
                      >
                        選擇帳號型態
                      </option>
                      <option value="0">
                        Email
                      </option>
                      <option value="1">
                        手機號碼
                      </option>
                      <!--                                 <option value="2">身分證號</option> -->
                    </select>
                  </div>
                  <small
                    class="text-danger position-absolute end-0"
                    :class="[
                      $style[`error-text`],
                      {
                        [$style[`show`]]:
                          v$.accountType?.$errors[0]?.$message !== ''
                      }
                    ]"
                  >{{ v$.accountType?.$errors[0]?.$message || '' }}</small>
                </div>
                <Input
                  id="validateMail"
                  v-model:input="state.validateMail"
                  type="text"
                  :required="true"
                  :autocomplete="'off'"
                  :label="labelMap.resetPwdAccount.label"
                  :placeholder="labelMap.resetPwdAccount.placeholder"
                  :err-message="v$.validateMail?.$errors[0]?.$message || ''"
                  @focus="v$.validateMail.$touch"
                  @enter="createRevalidationRequest"
                />
              </div>
              <div
                class="modal-footer justify-content-center p-0 mb-3 border-0"
              >
                <a
                  class="submitBtn btn btn-primary text-white rounded-0 m-2 px-4"
                  @click="createRevalidationRequest"
                >重新發送驗證通知</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
import Input from '@/components/Input.vue'
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import {
  requiredHelper,
  emailHelper,
  mobileHelper,
  helpers,
  or
} from '@/hooks/useVuelidate.js'

import { ref, inject, reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useErrorMessage } from '@/hooks/errorMessage.js'
// import { useFacebook } from '@/hooks/fb.js'

export default {
  name: 'ComponentLogin',
  components: {
    Input,
    InputPassword
  },
  async setup (props) {
    // const { initFacebookButton } = useFacebook()
    const { dispatch, commit, getters } = useStore()
    const { push } = useRouter()
    const { getErrorMessage } = useErrorMessage()

    function isMobile () {
      var u = navigator.userAgent
      // var app = navigator.appVersion
      var ua = navigator.userAgent.toLowerCase()

      return {
        // 偵測移動端瀏覽器版本信息
        trident: u.indexOf('Trident') > -1, // IE 核心
        presto: u.indexOf('Presto') > -1, // opera 核心
        webKit: u.indexOf('AppleWebKit') > -1, // Apple, google 核心
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // Firefox 核心
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 行動裝置
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios
        android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android或uc瀏覽器
        iPhone: u.indexOf('iPhone') > -1, // 是否為iPhone或者QQHD瀏覽器
        iPad: u.indexOf('iPad') > -1, // 是否iPad
        webApp: u.indexOf('Safari') === -1, // 是否web應用程式
        iosv: u.substr(u.indexOf('iPhone OS') + 9, 3), // ios版本
        weixin: ua.match(/MicroMessenger/i) === 'micromessenger', // 微信瀏覽器
        fbapp: u.indexOf('FBAV') > -1 || u.indexOf('FBAN') > -1, // Facebook App內瀏覽器
        line: u.indexOf('Line') > -1 // Line內瀏覽器
      }
    }

    const memberID = computed(() => getters['member/getMemberID'])

    // function Remakestatus () {
    //   window.FB.getLoginStatus(function (response) {
    //     console.log(response)
    //     if (response.status !== 'not_authorized') {
    //       response.status = 'not_authorized'
    //       console.log(response)
    //     }
    //   })
    // }

    // const facebookLogout = () => {
    //   window.FB.logout(function (response) {
    //   })
    // }

    // const statusChangeCallback = (response) => {
    //   window.FB.login(
    //     function (response) {
    //       const fbAccess = response.authResponse?.accessToken
    //       readHasSignUp(fbAccess)
    //     },
    //     {
    //       scope: 'public_profile,email',
    //       return_scopes: true,
    //       enable_profile_selector: true
    //     }
    //   )
    // }

    // const facebookOnLoginIcon = () => {
    //   if (isMobile().fbapp) {
    //     // window.location.assign('https://www.facebook.com/v6.0/dialog/oauth?client_id=2002777130011875&state=5734d3651ab948d90067dc1c97a1cfce&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https%3A%2F%2Flive02.168money.com.tw%2FTestLogin%2Ffbcallback.html&scope=email%2Cgroups_access_member_info')
    //     // window.location.assign('https://www.facebook.com/v6.0/dialog/oauth?client_id=1797225807143100&state=9671d2cf7fb8db8c5a9aa87a22db51a9&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https://www.nanahomecrystal.com/%2FTestLogin%2Ffbcallback.html&scope=email%2Cgroups_access_member_info')
    //     // window.location.assign('https://www.facebook.com/v13.0/dialog/oauth?client_id=1797225807143100&response_type=code&sdk=php-sdk-5.7.0&redirect_uri=https://www.nanahomecrystal.com/login&state=9671d2cf7fb8db8c5a9aa87a22db51a9&scope=email%2Cgroups_access_member_info')
    //   }
    //   window.FB.getLoginStatus(function (response) {
    //     statusChangeCallback(response)
    //   })
    // }

    // const readHasSignUp = async (fbAccess) => {
    //   const payload = {
    //     bindWithOrdinary: false,
    //     id_token: fbAccess,
    //     sso_type: 'facebook'
    //   }

    //   try {
    //     const response = await dispatch('member/createHasSignInRequest', {
    //       payload
    //     })

    //     push('/')
    //     setAlert(true, true, '登入成功！')

    //     return response
    //   } catch (error) {
    //     // setAlert(true, false, `登入失敗: ${error}`)
    //   }
    // }

    // const readHasSignUp = async (fbAccess) => {
    //   const payload = {
    //     bindWithOrdinary: false,
    //     id_token: fbAccess,
    //     sso_type: 'facebook'
    //   }

    //   try {
    //     const response = await dispatch('member/createHasSignInRequest', {
    //       payload
    //     })

    //     if (response.data.errMsg) {
    //       const errorCode = response.data.errMsg.split(':')[0]
    //       if (errorCode === '404-12') {
    //         commit('validate/setFbIDToken', { data: fbAccess })
    //         push('/signup')
    //       }
    //       setAlert(true, false, getErrorMessage(response))
    //     } else {
    //       push('/')
    //       setAlert(true, true, '登入成功！')
    //     }
    //     return response
    //   } catch (error) {
    //     setAlert(true, false, `登入失敗: ${error}`)
    //   }
    // }

    // console.warn('initFacebook')
    // initFacebook()
    onMounted(() => {
      // initFacebookButton()
      // window.facebookOnLoginIcon = facebookOnLoginIcon
      // Remakestatus()

      if (isMobile().line) {
        window.location.href =
          'https://www.nanahomecrystal.com/login?openExternalBrowser=1'
      }
      // if (isMobile().fbapp) {
      //   window.location.assign('googlechrome://www.nanahomecrystal.com/login')
      // }
      if (isMobile().fbapp && isMobile().android) {
        window.location.assign('intent:https://www.nanahomecrystal.com/login#Intent;end')
      }
      if (isMobile().fbapp && isMobile().ios) {
        window.location.assign('googlechrome://www.nanahomecrystal.com/login')
      }
      // if (isMobile().fbapp && isMobile().ios) {
      //   window.location.assign('http://www.nanahomecrystal.com/login')
      // }
    })

    const kaptcha = ref('')
    const readKaptcha = async () => {
      kaptcha.value = ''

      try {
        const response = await dispatch('validate/readKaptcha', { payload: {} })
        if (response?.data) {
          const image = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          )
          kaptcha.value = `data:${response.headers[
            'content-type'
          ].toLowerCase()};base64,${image}`
        } else {
          kaptcha.value = ''
        }
        return kaptcha.value
      } catch (error) {
        kaptcha.value = ''
        return ''
      }
    }

    const account = inject('account')
    const setAlert = inject('setAlert')

    const showResetPasswordModal = ref(false)
    const showRevalidationModal = ref(false)

    const setShowResetPasswordModal = (show) => {
      showResetPasswordModal.value = show
      v$.value.$reset()
    }
    const setShowRevalidationModal = (show) => {
      showRevalidationModal.value = show
      v$.value.$reset()
    }

    const fieldListLogin = ['account', 'password']

    const labelMap = {
      account: {
        label: '電子信箱或手機號碼',
        placeholder: '請輸入電子信箱或手機號碼'
      },
      password: {
        label: '密碼',
        placeholder: '請輸入密碼'
      },
      //   verification: {
      //     label: '驗證碼',
      //     placeholder: '請輸入驗證碼'
      //   },
      resetPwdAccount: {
        label: '帳號',
        placeholder: '請輸入帳號'
      },
      validateMail: {
        label: '帳號',
        placeholder: '請輸入帳號'
      }
    }

    const styleMapLogin = {
      account: 'mb-4',
      password: 'mb-4'
      //   verification: 'col-6'
    }

    const state = reactive({
      account,
      password: '',
      //   verification: '',
      accountType: 0,
      validateMail: '',
      resetPwdAccount: ''
    })

    const rules = computed(() => {
      const ruleMapLogin = {
        account: {
          requiredHelper,
          shouldBeChecked: helpers.withMessage(
            '格式錯誤',
            or(emailHelper, mobileHelper)
          )
        },
        password: { requiredHelper },
        // verification: { requiredHelper },
        accountType: {},
        resetPwdAccount: {},
        validateMail: {}
      }

      const ruleMapResetPwd = {
        account: {},
        password: {},
        // verification: {},
        accountType: { requiredHelper },
        resetPwdAccount: { requiredHelper },
        validateMail: {}
      }

      const ruleMapRevalidation = {
        account: {},
        password: {},
        // verification: {},
        accountType: { requiredHelper },
        resetPwdAccount: {},
        validateMail: { requiredHelper }
      }

      if (showResetPasswordModal.value) {
        if (state.accountType === '0') {
          return Object.assign({}, ruleMapResetPwd, {
            resetPwdAccount: { requiredHelper, emailHelper }
          })
        } else if (state.accountType === '1') {
          return Object.assign({}, ruleMapResetPwd, {
            resetPwdAccount: { requiredHelper, mobileHelper }
          })
        } else {
          return Object.assign({}, ruleMapResetPwd, {
            resetPwdAccount: { requiredHelper }
          })
        }
        // return Object.assign({}, ruleMapResetPwd)
      } else if (showRevalidationModal.value) {
        if (state.accountType === '0') {
          return Object.assign({}, ruleMapRevalidation, {
            validateMail: { requiredHelper, emailHelper }
          })
        } else if (state.accountType === '1') {
          return Object.assign({}, ruleMapRevalidation, {
            validateMail: { requiredHelper, mobileHelper }
          })
        } else {
          return Object.assign({}, ruleMapRevalidation, {
            validateMail: { requiredHelper }
          })
        }
      } else {
        return ruleMapLogin
      }
    })

    const v$ = useVuelidate(rules, state)

    const readCartMount = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value }

        try {
          const response = await dispatch('cart/readCartMount', { payload })
          return response
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
          // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const readCart = async () => {
      if (memberID.value) {
        const payload = { memberID: memberID.value, offset: 0, pageSize: 10 }

        try {
          const response = await dispatch('cart/readCart', { payload })
          if (response.data.code !== 200) {
            // setAlert(true, false, response.data.errMsg)
          }
        } catch (error) {
          console.log('send contact form error:' + ', ' + error)
          // setAlert(true, false, error)
        }
      } else {
        commit('cart/setCartMount', { data: 0 })
      }
    }

    const readMemberID = async () => {
      v$.value.$touch()

      const payload = {
        account: account.value,
        pwd: state.password
        // verification: state.verification
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch('member/readMemberID', { payload })
          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            state.verification = ''
            readKaptcha()
            console.log(response)

            // if is wrong password, clear password, focus password input
            if (response.data.errMsg.split(':')[0] === '404-4') {
              state.password = ''
              document.getElementById('password').focus()
            }
          } else {
            setAlert(true, true, '登入成功！')
            await readCartMount()
            await readCart()
            push('/')
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `登入失敗: ${error}`)
          //   state.verification = ''
          readKaptcha()
        }
      } else {
        // state.verification = ''
        readKaptcha()
      }
    }

    const createResetPasswordRequest = async () => {
      v$.value.$touch()
      const payload = {
        resetPwdAccount: state.resetPwdAccount,
        accountType: state.accountType
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch(
            'validate/createResetPasswordRequest',
            { payload }
          )

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            setShowResetPasswordModal(false)
            state.accountType = null
          } else {
            setAlert(true, true, '發送成功')
            setShowResetPasswordModal(false)
            state.accountType = null
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `發送失敗: ${error}`)
          setShowResetPasswordModal(false)
          state.accountType = null
        }
      }
    }

    const createRevalidationRequest = async () => {
      v$.value.$touch()

      const payload = {
        validateMail: state.validateMail,
        accountType: state.accountType
      }

      if (!v$.value.$error) {
        try {
          const response = await dispatch(
            'validate/createRevalidationRequest',
            { payload }
          )

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
            setShowRevalidationModal(false)
            state.accountType = null
          } else {
            setAlert(true, true, '發送成功')
            setShowRevalidationModal(false)
            state.accountType = null
            v$.value.$reset()
          }

          return response
        } catch (error) {
          setAlert(true, false, `發送失敗: ${error}`)
          setShowRevalidationModal(false)
          state.accountType = null
        }
      }
    }

    await readKaptcha()

    return {
      v$,
      fieldListLogin,
      account,
      styleMapLogin,
      state,
      kaptcha,
      labelMap,
      readKaptcha,
      readMemberID,
      showResetPasswordModal,
      showRevalidationModal,
      setShowResetPasswordModal,
      setShowRevalidationModal,
      createResetPasswordRequest,
      createRevalidationRequest,
      // facebookOnLogin,
      isMobile
      // Remakestatus,
      // facebookLogout,
    }
  }
}
</script>
<style lang="scss" module>
.minHeight60 {
  min-height: 60vh;
}

.fb {
  fill: $white;
  height: 1.2rem;
  width: 1.2rem;
}

.orBorder::before {
  border-bottom: solid 1px $gray-400;
  bottom: 0;
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  width: calc(100% - 3rem);
}

.or {
  bottom: 0;
  color: $gray-400;
  left: 50%;
  transform: translate(-50%, -50%);
}

@include media-breakpoint-up(md) {
  .orBorder::before {
    border-bottom: unset;
    border-left: solid 1px $gray-400;
    height: 100%;
    transform: translate(-1.5rem, 0);
  }

  .or {
    bottom: unset;
    left: 0;
    top: 50%;
  }
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}

.height-100px {
  height: 100px;
}

.img-contain {
  height: 100px;
  min-height: 100px;
  object-fit: contain;
  width: 100%;
}

.shadow {
  background-color: rgba($dark, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: calc(#{$zindex-modal} - 1);
}

.modal {
  max-width: 600px;
  width: 80vw;
  z-index: $zindex-modal;
}

@media screen and (max-width: 768px) {
  .or {
    opacity: 0;
  }

  .orBorder::before {
    border-bottom: solid 1px $gray-400;
    bottom: 0;
    content: '';
    display: block;
    margin-bottom: -20px;
    position: absolute;
    width: 100%;
    width: calc(100% - 3rem);
  }
}
</style>
